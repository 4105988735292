
import { defineComponent } from 'vue';
import Card from '@/views/new-design/components/Card.vue';
import DataTable from '@/components/datatables/KTDataTable.vue';
import { Actions, Getters, Mutations } from '@/store/enums/ReferenceEnums';
import { mapActions, mapGetters } from 'vuex';
import TenantAvatar from '@/components/avatars/Avatar.vue';
import useBreakpoints from 'vue-next-breakpoints';
import text from '@/utils/text';

// import AgencyLogo from '@/views/new-design/pages/TenantReferences/AgencyLogo.vue';
export default defineComponent({
  components: { Card, DataTable, TenantAvatar },
  data: () => {
    return {
      table: {
        currentPage: 1,
        totalPages: 10,
        perPage: 15,
        total: 0,
        items: [],
        ordering: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      references: Getters.GET_REFERENCES_LISTS,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
    tableHeaders() {
      return [
        { name: 'Tenant Name', key: 'name' },
        { name: 'Tenant Rating', key: 'rating' },
        { name: 'Submitted Date', key: 'date', hidden: this.isMobile },
        { name: 'Agency', key: 'agency', hidden: this.isMobile },
        { name: 'Submitted By', key: 'agent_name', hidden: this.isMobile },
      ];
    },
  },
  methods: {
    ...mapActions({
      fetchReferences: Actions.FETCH_REFERENCES,
    }),
    formatTenantReferences() {
      return this.references.map((item) => {
        const name = `${item.tenant.first_name} ${item.tenant.last_name}`;

        item.avatar = item.tenant.avatar_src;
        item.name = name;
        item.rating = parseFloat(item.average_rating);
        item.date = item.date_created;
        item.agency = item.user.business_name;
        item.agent_name = `${item.user.first_name} ${item.user.last_name}`;
        item.acronym = text.acronym(name);

        return item;
      });
    },
    async getTenantReferences() {
      await this.fetchReferences({ returnAll: true }).then(() => {
        this.table.items = this.formatTenantReferences();
        this.table.total = this.references.length;
      });
    },
    viewReport(ref: any) {
      this.$router.push({
        name: 'view-tenant-reference',
        params: { tenant_id: ref.tenant.id, tenant_ref_id: ref.reference_no },
      });
    },
  },
  async mounted() {
    this.getTenantReferences();
  },
});
